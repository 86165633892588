/* Media Queries */ 
// $media_queries : (
//     'mobile'    : "only screen and (max-width: 667px)",
//     'tablet'    : "only screen and (min-width: 668px) and (max-width: $size__site_content_width)",
//     'desktop'   : "only screen and (min-width: ($size__site_content_width + 1))",
//     'retina2'   : "only screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)",
//     'retina3'   : "only screen and (-webkit-min-device-pixel-ratio: 3) and (min-resolution: 288dpi)",
//     'landscape' : "screen and (orientation:landscape) ",    
//     'portrait'  : "screen and (orientation:portrait) "
//     );

    @mixin for-size($size) {
        @if $size == phone-only {
          @media (max-width: 667px) { @content; }
        } @else if $size == tablet-portrait-up {
          @media (min-width: 668px) and (max-width: 900px) { @content; }
        } @else if $size == tablet-landscape-up {
          @media (min-width: 900px) { @content; }
        } @else if $size == desktop-up {
          @media (min-width: 1200px) { @content; }
        } @else if $size == big-desktop-up {
          @media (min-width: 1800px) { @content; }
        }
      }