@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap');

$color-light-gray: #eaece5;

.coming-soon {
  height: 75rem;
  padding-top: 20rem;
  background: url('../images/coming-soon.jpg');
  background-size: cover;
}

.paper-text {
  font-family: 'Raleway', sans-serif;
  font-size: 10rem;
  position: relative;
  text-transform: full-width;
  display: flex;
  justify-content: center;

  .space {
    width: 0.3em;
  }

  .letter {
    position: relative;
    display: flex;

    .source {
      color: gray;
      -webkit-text-stroke: 0.01em rgba(black, 0.3);
      display: flex;
    }

    .overlay,
    .shadow {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      user-select: none;
    }

    .overlay {
      background-image: linear-gradient(
        90deg,
        white 50%,
        lighten($color-light-gray, 2)
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      transform: rotateY(-30deg) skew(0, -10deg);
      transform-origin: left;
      animation: overlay 3s infinite ease-out var(--delay);
    }

    .shadow {
      filter: blur(5px);
      background-image: linear-gradient(
        90deg,
        rgba(black, 0.4) 30%,
        transparent
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      transform: skew(0, 20deg) translateY(0.1em) translateX(0.05em);
      animation: shadow 3s infinite var(--delay);
    }
  }
}

@keyframes shadow {
  0%,
  20%,
  100% {
    transform: skew(0, 20deg) translateY(0.1em) translateX(0.05em);
    opacity: 1;
  }
  10% {
    transform: skew(0, 0) translateY(0) translateX(0);
    opacity: 0;
  }
}

@keyframes overlay {
  0%,
  20%,
  100% {
    transform: rotateY(-30deg) skew(0, -10deg);
  }
  10% {
    transform: rotateY(0deg) skew(0, 0);
  }
}
