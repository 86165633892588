@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

.about {
  height: auto;
  padding: 4rem 5rem;
  display: flex;
  flex-direction: column;
  font-family: 'Lora', serif;
  font-optical-sizing: auto;

  .about-heading {
    font-size: 3.6rem;
    color: #2b2b2b;
    margin-top: 2rem;
  }
  .about-info {
    display: flex;
    justify-content: space-around;
    line-height: 40px;
    margin-top: 1.8rem;

    @include for-size(phone-only) {
      height: auto;
      flex-direction: column;
    }

    p {
      width: 45%;
      font-size: 2.1rem;

      @include for-size(phone-only) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
}
