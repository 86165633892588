@import "./reset.scss";
@import "./helpers/variables.scss";
@import "./app.scss";
@import "./header.scss";
@import "./hero.scss";
@import "./about.scss";
@import "./skills.scss";
@import "./comingsoon.scss";
@import "./footer.scss";

html, body {
    margin: 0;
    height: 100%;
    min-height: 100%;
    width: 100%;
    font-size: 62.5%;
    //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
     // "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // color:$main-font-color;
    // background-color: $main-background-color;
  }
  
  * {
    box-sizing: border-box;
  }
  
//   h1, h2, h3, h4, h5 {
//     font-family: $slogan-font;
//     color: $header-font-color;
//   }

//   p {
//     font-size: 1.2rem;
//   }
  
  #root {
    height: 100%;
  }
