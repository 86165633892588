@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.footer {
  height: 60rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #edece8;

  @include for-size(phone-only) {
    height: auto;
    flex-direction: column;
    text-align: center;
  }
}

.contact-img {
  border-radius: 50%;
  border: 12px solid #d44638;
  @include for-size(phone-only) {
    width: 50%;
  }
}

.email-container {
  line-height: 4.4rem;
  color: #2b2b2b;

  a {
    color: #ff4131;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }

  h3 {
    font-family: 'Lora', serif;
    font-size: 3.6rem;
    font-weight: 700;
  }

  h5 {
    font-size: 2.5rem;
  }

  .email-info {
    display: flex;
    font-size: 2.8rem;
    .mail-icon {
      width: 28px;
      margin-right: 1rem;
    }
  }
}

.social-container {
  margin-top: 3rem;
  line-height: 44px;

  h5 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2.9rem;
    color: #2b2b2b;
  }
}

.icon-container {
  display: flex;
  justify-content: center;

  img {
    width: 28px;
    margin-right: 6rem;
    margin-top: 2rem;

      @include for-size(phone-only) {
    margin-right: 2rem;
  }
  }
}
