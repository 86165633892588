@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Rock+Salt&display=swap');

.header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    height:8rem;
    position: fixed;
    background: rgba(0,0,0,0.3);

    @include for-size(phone-only ){
        height: 5rem;
        flex-direction: column;
    }
}

.header-logo {
    font-family: "Rock Salt", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 3rem;
    @include for-size(phone-only ){
        margin-top: 1rem;
        font-size: 1rem;
    }

    @include for-size(tablet-portrait-up){
        font-size: 2rem;
    }

    a {
        text-decoration: none;
        color:  #9b2c15;
    }
}

.header-container {
    display: flex;
    justify-content: space-around;
    margin: 0.5rem 0 ;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 1.8rem;

    a, a:visited {
        margin: 0 1rem;
        text-decoration: none;
        color: #fff; 
    }

    a:hover {
        color:  #9b2c15;
    }
}