@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Rock+Salt&display=swap');

.hero-profile {
    display: flex;
    justify-content: center;
    align-items: center;

    .hero-img {
        width: 100%;
    }

    .hero-content{
        position: absolute;
        padding-bottom: 35rem;
        width: min-content;

        @include for-size(phone-only ){
          width: initial;
          padding-bottom: 7rem;
          padding-left: 3rem;
          display: flex;
          text-align: center;
        }

        @include for-size(tablet-portrait-up ){
            padding-bottom: 0;
            width: auto;
            margin-left: 10rem;
            margin-bottom: 13rem;
        }
        
        title {
            font-family: "Lora", serif;
            font-size:10rem;
            display: flex;
            line-height: 11rem;
            color: #9b9b9b;

            @include for-size(phone-only ){
                font-size: 2rem;
                line-height: 2rem;
            }

            @include for-size(tablet-portrait-up ){
                font-size: 4rem;
                line-height: 5rem;
            }

            .color-change {
                color: #1b1b1b;
            }

            #cycle {
                white-space: pre-wrap;
            }
            #cycle:after {
                animation-name:cycle;
                animation-duration: 6s;
                animation-iteration-count: infinite;
                content: "";
              }
            }
            @keyframes cycle {
              0% {
                content: " Front End ";
              }
              33% {
                content: " Back End ";
              }
              66% {
                content: " Full Stack ";
              }
              100% {
                content: " Software ";
              }
            
            
        }
    }

}
